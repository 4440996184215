import styled from 'styled-components';
import { color, flexbox, layout, space, position } from 'styled-system';

const Box = styled.div(
  space,
  color,
  flexbox,
  layout,
  position
);

export default Box;
