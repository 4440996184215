import React from 'react';
import { H2, List, ListItem, NoWrap, Section, Strong, Text } from '../Typography';

const isSpanish = navigator.language.startsWith('es');

const VideoList = () => (
  <List>
    <ListItem>The Muppets Take Manhattan</ListItem>
    <ListItem>My Big Fat Greek Wedding</ListItem>
    <ListItem>Muriel’s Wedding</ListItem>
    <ListItem>Doctor Who S4</ListItem>
    <ListItem>The Little Mermaid</ListItem>
    <ListItem>Addams Family Values</ListItem>
    <ListItem>Corpse Bride</ListItem>
    <ListItem>Sixteen Candles</ListItem>
    <ListItem>Coming to America</ListItem>
    <ListItem>Bride of Chucky</ListItem>
    <ListItem>Deep Space 69</ListItem>
    <ListItem>Doctor Who S5</ListItem>
    <ListItem>Gremlins 2</ListItem>
    <ListItem>Up</ListItem>
    <ListItem>Norbit</ListItem>
    <ListItem>Futurama S7</ListItem>
    <ListItem>GOT S5</ListItem>
    <ListItem>Adventure Time S5</ListItem>
    <ListItem>The Wedding Singer</ListItem>
    <ListItem>Steven Universe S5</ListItem>
    <ListItem>I Now Pronounce You Chuck and Larry</ListItem>
    <ListItem>Bride Wars</ListItem>
    <ListItem>Saving Silverman</ListItem>
    <ListItem>Monster-in-Law</ListItem>
    <ListItem>SATC: The Movie</ListItem>
    <ListItem>Wayne’s World 2</ListItem>
    <ListItem>Clueless</ListItem>
    <ListItem>Power Rangers RPM</ListItem>
    <ListItem>The Godfather</ListItem>
    <ListItem>Zootopia</ListItem>
    <ListItem>Beetlejuice</ListItem>
    <ListItem>Napoleon Dynamite</ListItem>
    <ListItem>The Waterboy</ListItem>
    <ListItem>The Muppets Take Manhattan</ListItem>
  </List>
);

const En = () => (
  <>
    <H2 mb={4}>&gt;3 the intro video!<br />Where are all the <NoWrap>movie clips from?</NoWrap></H2>
    <Text mb={3}>The Internet is a magical place.</Text>
    <Text><Strong>In order, the clips are from:</Strong></Text>
    <VideoList />
    <Text mt={3}>And yes, Adam Sandler makes three appearances.</Text>
  </>
);

const Es = () => (
  <>
    <H2 mb={4}>¡Que chulo el video!<br />¿De dónde son los <NoWrap>clip de película?</NoWrap></H2>
    <Text mb={3}>El Internet es un sitio mágico.</Text>
    <Text><Strong>En orden, los clips son de:</Strong></Text>
    <VideoList />
    <Text mt={3}>Y sí, Adam Sandler aparece tres veces.</Text>
  </>
);

export default function IntroVideos() {
  return (
    <Section mb={0}>
      {isSpanish
        ? <Es />
        : <En />
      }
    </Section>
  );
}
