import styled from 'styled-components';
import { color, position } from 'styled-system';
import css from '@styled-system/css';

import React from 'react';
import PropTypes from 'prop-types';

const StyledButton = styled.button(
  position,
  ({ isHidden }) => (
    css({
      pointerEvents: isHidden && 'none',
      display: 'flex',
      alignContent: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      width: 'minTarget',
      height: 'minTarget',
      opacity: isHidden
        ? 0
        : 1,
      ':focus': {
        outline: 'none'
      }
    })
  )
);

const Icon = styled.div(
  color,
  css({
    width: 'icon',
    height: 'icon',
    padding: '2px',
    borderRadius: '50%',
    borderStyle: 'solid',
    borderColor: 'transparent.pink',
    borderWidth: '2px',
    transition: 'all 0.25s cubic-bezier(0.785, 0.135, 0.15, 0.86)',
    '&:active, &:focus, &:hover': {
      backgroundColor: 'blue',
      color: 'white'
    },
    '&:hover': {
      color: 'green'
    }
  })
);

export default function IconButton({ icon, title, ...styleProps }) {
  return (
    <StyledButton title={title} {...styleProps}>
      <Icon {...styleProps}>{icon}</Icon>
    </StyledButton>
  );
}

IconButton.propTypes = {
  title: PropTypes.string.isRequired
};

