import React from 'react';
import { H3, Section, NoWrap, Text } from '../Typography';

const isSpanish = navigator.language.startsWith('es');

const En = () => (
  <>
    <H3>Why wasn’t <NoWrap>so-and-so invited?</NoWrap></H3>
    <Text>Real talk, since we’re paying for the wedding ourselves, we had to cut down on the guests we could invite. Diana’s family alone is too big to invite every single loved one. Just have a great time and know that if you were invited, you’re very special to both of us.</Text>
  </>
);

const Es = () => (
  <>
    <H3>¿Por qué no invitaron <NoWrap>a fulano/fulana?</NoWrap></H3>
    <Text>La realidad de la vida, es que cómo estamos pagando la boda nosotros mismos, tuvimos que cortar el número de personas que podíamos invitar. La familia de Diana solamente es demasiado grande para invitar a todos sus seres queridos. No le dé mente al que no está invitado y pásela bien sabiendo que usted que fue invitado es muy especial en nuestras vidas.</Text>
  </>
);

export default function NotInvited() {
  return (
    <Section>
      {isSpanish
        ? <Es />
        : <En />
      }
    </Section>
  );
}
