import React from 'react';
import { H2, Section, Text } from '../Typography';

const isSpanish = navigator.language.startsWith('es');

const En = () => (
  <>
    <H2>Gifts</H2>
    <Text>After living together for over 13 years, we really don’t need anything home-related. But that cash money helps pay for the wedding and maybe even our honeymoon. You can either bring an envelope with cash to the wedding or use your favorite online payment service:</Text>
    <Text>Venmo to @casthart</Text>
    <Text>PayPal/Zelle to flotion@gmail.com</Text>
  </>
);

const Es = () => (
  <>
    <H2>Regalos</H2>
    <Text>Después de vivir más de 13 años juntos, realmente no necesitamos nada para la casa. Pero el dinero ayuda pagar la boda y tal vez hasta nuestra luna de miel. Pueden traer un sobre con dinero a la boda o usar su servicio de pago favorito:</Text>
    <Text>Venmo a @casthart</Text>
    <Text>PayPal/Zelle a flotion@gmail.com</Text>
  </>
);

export default function CashGifts() {
  return (
    <Section>
      {isSpanish
        ? <Es />
        : <En />
      }
    </Section>
  );
}
