import React, { useLayoutEffect } from 'react';
import { ThemeProvider } from 'styled-components'
import { Router } from '@reach/router';

import theme from './theme';

import { ScrollToTop } from './components';
import { Photo, Photos, Splash, Video } from './pages';

function setViewportVar() {
  // css-tricks.com/the-trick-to-viewport-units-on-mobile/
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
}

function App() {
  useLayoutEffect(setViewportVar);

  return (
    <ThemeProvider theme={theme}>
      <>
        <Router primary={false}>
          <ScrollToTop path="/">
            <Splash path="/" />
            <Photos path="/photos" />
            <Photos path="/photos/albums/:albumId" />
            <Photos path="/photos/search" />
            <Photo path="/photos/:imageId" />
            <Video path="/video" />
            <Video path="/video-booth" />
          </ScrollToTop>
        </Router>
      </>
    </ThemeProvider>
  );
}

window.addEventListener('resize', setViewportVar);

export default App;
