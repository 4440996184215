import styled from 'styled-components';
import css from '@styled-system/css';

import React from 'react';
import { useDocumentTitle } from '../hooks';

import { Nav, Layout } from '../components';

const MaintainAspectRatio = styled.div(
  css({
    position: 'relative',
    width: '90%',
    paddingTop: '56.25%'
  })
);

const VimeoEmbed = styled.iframe(
  css({
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%'
  })
);

export default function Video({ location, navigate }) {
  const isVideoBooth = location.pathname.includes('booth');

  const title = isVideoBooth
    ? 'Video Booth'
    : 'Wedding Video';

  useDocumentTitle(title);

  const videoId = isVideoBooth
    ? '381131875'
    : '381131703'

  const playerOptions = [
    'autoplay=1',
    'title=0',
    'byline=0',
    'portrait=0'
  ].join('&');

  return (
    <Layout display="flex" alignItems="center" justifyContent="center" height="viewportHeight" showBackground>
      <Nav
        width="sidebar"
        navigate={navigate}
        path={location.pathname}
      />
      <MaintainAspectRatio>
        <VimeoEmbed
          src={`https://player.vimeo.com/video/${videoId}?${playerOptions}`}
          frameBorder="0"
          allow="autoplay; fullscreen"
          allowFullscreen="true"
        />
      </MaintainAspectRatio>
    </Layout>
  );
}
