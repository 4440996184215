import React from 'react';
import { Link as RouterLink } from '@reach/router';
import styled from 'styled-components';
import css from '@styled-system/css';

const linkStyles = css({
  color: 'green',
  fontWeight: 400,
  textDecoration: 'underline',
  textDecorationSkipInk: 'auto',
  textShadow: 'none',
  '&:hover': {
    color: 'pink'
  }
});

const StyledLink = styled('a')(linkStyles);
const StyledRouterLink = styled(RouterLink)(linkStyles);

export default function Link({ children, to } = {}) {
  return to.startsWith('http')
    ? <StyledLink href={to} target="_blank">{children}</StyledLink>
    : <StyledRouterLink to={to}>{children}</StyledRouterLink>
}
