import React from 'react';
import { H3, Section, Text } from '../Typography';

const isSpanish = navigator.language.startsWith('es');

const En = () => (
  <>
      <H3>What’s happening in Governors Island?</H3>
      <Text>The day after the wedding at noon (if you’re still standing), we want to keep the party going on Governors Island. There will be food/drinks for purchase and an amazing view of the cityscape you just can’t beat. If you’ve never been, you can take a ferry from Pier 6 in Brooklyn (ATLANTIC AVENUE AND BROOKLYN BRIDGE PARK GREENWAY) or the Battery Maritime Building in Manhattan (10 SOUTH STREET). Make sure to catch the ferry leaving at 11:30am to get there on time.</Text>
  </>
);

const Es = () => (
  <>
    <H3>¿Qué esta pasando en Governors Island?</H3>
    <Text>El día después de la boda al mediodía (si aguantas), queremos seguir celebrando en la isla de Governor’s Island. Habrá comida/bebidas que pueden comprar y una vista que no se puede comparar. Si nunca ha ido, puede tomar un ferry en Pier 6 desde Brooklyn (ATLANTIC AVENUE AND BROOKLYN BRIDGE PARK GREENWAY) o el edificio Battery Maritime Building desde Manhattan (10 SOUTH STREET). Asegurarse de tomar el ferry que sale a las 11:30am para llegar a tiempo.</Text>
  </>
);

export default function GovIsland() {
  return (
    <Section>
      {isSpanish
        ? <Es />
        : <En />
      }
    </Section>
  );
}
