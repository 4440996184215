import css from '@styled-system/css';
import get from 'lodash.get';
import fetchData from '../util/fetchData';
import styled from 'styled-components';

import React from 'react';
import { useQuery } from '../hooks';

import { Link } from '@reach/router';
import { Box, Image } from '../components';

const Album = styled(Link)(
  css({
    cursor: 'pointer',
    display: 'block',
    position: 'relative'
  })
);

const AlbumTitle = styled.h4(
  css({
    position: 'absolute',
    bottom: 4,
    right: 4,
    fontWeight: 400,
    color: 'green',
    '&:hover': {
      color: 'pink'
    }
  })
);

export default function Photos({ width }) {
  const { data: resource, isLoading, error } = useQuery('albums', () => fetchData('/albums'));

  if (error || isLoading || !resource) return null;
  if (error) console.error(error);

  const albums = get(resource, 'data', []);

  return (
    <Box width={width}>
      {albums.map(album => (
        <Album key={album.id} to={`/photos/albums/${album.id}`}>
          <Image
            title={album.title}
            width={album.coverImage.width}
            height={album.coverImage.height}
            sizes={album.coverImage.sizes}
            isLink
          />
          <AlbumTitle bottom={4} right={4}>{album.title}</AlbumTitle>
        </Album>
      ))}
    </Box>
  );
}
