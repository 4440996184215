import css from '@styled-system/css';
import styled from 'styled-components';

import React, { useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import Autosuggest from 'react-autosuggest';
import { normalizeSync } from 'normalize-diacritics';

import theme from '../theme';

const CHANGE_DEBOUNCE = 500;

const styles = {
  text: {
    fontFamily: 'ui',
    fontSize: [2, 1],
    textTransform: 'uppercase',
    letterSpacing: '0.1em'
  },
  truncate: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  }
};

const Input = styled.input.attrs(
  {
    autoComplete: 'false',
    spellCheck: 'false'
  }
)(
  css({
    ...styles.text,
    appearance: 'none',
    boxSizing: 'border-box',
    width: '100%',
    height: 'minTarget',
    marginBottom: 3,
    border: 0,
    borderTopLeftRadius: 2,
    borderTopRightRadius: 2,
    borderBottomLeftRadius: 2,
    borderBottomRightRadius: 2,
    padding: `calc(${theme.sizes.minTarget} - 2em) 1em`,
    backgroundColor: 'darkGreen',
    color: 'memoOrange',
    lineHeight: 2,
    '&:focus': {
      outline: 'none'
    },
    '::placeholder': {
      color: 'pink'
    }
  })
);

const Suggestions = styled.div(
  css({
    position: 'absolute',
    top: 'minTarget',
    right: 0,
    left: 0
  })
);

const Value = styled.span(
  ({ isHighlighted }) => (
    css({
      ...styles.text,
      ...styles.truncate,
      cursor: 'pointer',
      userSelect: 'none',
      display: 'block',
      px: '1em',
      lineHeight: 'minTarget',
      color: isHighlighted
        ? 'white'
        : 'blue',
      backgroundColor: isHighlighted
        ? 'blue'
        : 'memoOrange'
    })
  )
);

const MatchText = styled.span(
  ({ isHighlighted }) => (
    css({
      borderColor: 'blue',
      borderStyle: 'solid',
      borderWidth: 0,
      borderBottomWidth: isHighlighted
        ? '0'
        : '2px',
    })
  )
);

function SuggestionsValue(suggestion, { query, isHighlighted }) {
  const text = suggestion.name;
  const pattern = new RegExp(`(${query})`, 'i');
  const matchGroups = text.toLowerCase().split(pattern);

  if (matchGroups.length > 1) {
    return (
      <Value isHighlighted={isHighlighted}>
        {matchGroups.map((group, index) => (
          group === query
            ? <MatchText key={index} isHighlighted={isHighlighted}>{group}</MatchText>
            : group
        ))}
      </Value>
    );
  }

  return (
    <Value isHighlighted={isHighlighted}>
      {text}
    </Value>
  );
}

function getSuggestions(query, options) {
  if (query.length < 3) return [];

  return options.filter(option => {
    const matchQuery = normalizeSync(query).toLowerCase();

    let name = option.name || '';
    let alias = option.alias || '';
    if (name) name = normalizeSync(option.name).toLowerCase();
    if (alias) alias = normalizeSync(option.alias).toLowerCase();

    if (name.startsWith(matchQuery)) return true;
    if (alias.startsWith(matchQuery)) return true;
    if (name.includes(matchQuery)) return true;
    if (alias.includes(matchQuery)) return true;

    return false;
  });
}

function getSuggestionValue(suggestion) {
  return suggestion.name;
}

function renderSuggestionsContainer({ containerProps, children }) {
  if (!children) return null;
  return (
    <Suggestions {...containerProps}>
      {children}
    </Suggestions>
  );
}

export default function Search({ options = [], path, onChange }) {
  const [suggestions, setSuggestions] = useState([]);
  const [value, setValue] = useState('');

  function clearSuggestions() {
    setSuggestions([]);
  }

  function handleChange(event, { newValue }) {
    setValue(newValue);
  }

  function handleSelection(event, { suggestionValue }) {
    if (typeof onChange === 'function') onChange(suggestionValue);
  }

  const [handleFetch] = useDebouncedCallback(({ value }) => {
    setSuggestions(
      getSuggestions(value, options)
    );
  }, CHANGE_DEBOUNCE);

  useEffect(() => {
    if (path.includes('search')) return;
    setValue('');
  }, [path]);

  return (
    <Autosuggest
      suggestions={suggestions}
      onSuggestionsFetchRequested={handleFetch}
      onSuggestionsClearRequested={clearSuggestions}
      onSuggestionSelected={handleSelection}
      getSuggestionValue={getSuggestionValue}
      renderInputComponent={props => <Input {...props} hasValue={Boolean(props.value)} />}
      renderSuggestion={SuggestionsValue}
      renderSuggestionsContainer={renderSuggestionsContainer}
      inputProps={{
        value,
        placeholder: 'Search by name…',
        onChange: handleChange
      }}
      theme={{
        container: {
          position: 'relative'
        }
      }}
    />
  );
}
