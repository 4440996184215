const requestOptions = {
  headers: {
    accept: 'application/json'
  }
};

export default async function fetchData(url, params = {}) {
  if (typeof url !== 'string') {
    throw new Error('Request URL is required');
  }

  const urlWithParams = new URL(`/.netlify/functions${url}`, window.location.origin);

  for (const key in params) { // eslint-disable-line
    let value = params[key];
    if (value && value !== false) urlWithParams.searchParams.set(key, value);
  }

  try {
    const response = await window.fetch(
      urlWithParams.toString(),
      requestOptions
    );
    return response.json();
  } catch (err) {
    throw err;
  }
}
