import styled from 'styled-components';
import { color, flexbox, layout, space, position } from 'styled-system';

const ContentBox = styled.div(
  space,
  color,
  flexbox,
  layout,
  position,
  {
    boxSizing: 'content-box'
  }
);

export default ContentBox;
