import css from '@styled-system/css';
import styled from 'styled-components';

import React, { useState } from 'react';

import { Button, IconButton, Layout } from '../components';
import { NoWrap } from '../Typography';
import IdealImage from 'react-ideal-image';

import theme from '../theme';
import { EASE_IN_OUT_CIRC } from '../consts/easings';
import { ReactComponent as ActionsIcon } from '../artwork/actions.svg';
import { ReactComponent as CloseIcon } from '../artwork/close.svg';

const Actions = styled.div(
  ({ maxSmall, isOpen, isPortrait }) => (
    css({
      display: 'flex',
      flexDirection: 'column',
      justifyContent: maxSmall || isPortrait
        ? 'top'
        : 'center',
      alignItems: 'center',
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      padding: 'margin',
      opacity: isOpen
        ? 1
        : 0,
      transition: `all 0.5s ${EASE_IN_OUT_CIRC}`,
      backgroundColor: 'transparent.darkestBlue'
    })
  )
);

const Copyright = styled.p(
  css({
    position: 'absolute',
    bottom: [3, 'margin'],
    left: 2,
    right: 2,
    fontSize: 2,
    fontFamily: 'ui',
    letterSpacing: '0.3em',
    color: 'memoOrange',
    textAlign: 'center',
    textTransform: 'lowercase'
  })
);

export default function Image({ title, layoutWidth = '100%', width, height, sizes = [], downloadUrl, smugmugUrl, maxSmall, isLink }) {
  const [isVisible, setActionsVisibility] = useState(false);
  const [isOpen, setActionsOpen] = useState(false);

  function toggleActionsVisibility(event) {
    setActionsVisibility(!isVisible);
  }

  function openActions(event) {
    if (isLink || isOpen) return;
    if (event.type === 'mouseenter' && maxSmall) return;
    setActionsOpen(true);
  }

  function closeActions(event) {
    if (isLink || !isOpen) return;
    if (event.type === 'mouseleave' && maxSmall) return;
    setActionsOpen(false);
  }

  const image = (
    <IdealImage
      alt={title}
      width={width}
      height={height}
      loader="image"
      placeholder={{
        color: theme.colors.transparent.darkestGreen
      }}
      theme={{
        img: {
          width: layoutWidth,
          height: 'auto',
          maxWidth: '100%'
        }
      }}
      srcSet={sizes.map(size => ({
        width: size.width,
        src: size.url
      }))}
    />
  );

  const buyUrl = `${smugmugUrl}/buy`;
  const isPortrait = height > width;

  return (
    <Layout position="relative" height="auto">
      {image}
      {!isLink && (
        <>
          <Actions isOpen={isOpen} isPortrait={isPortrait} onClick={toggleActionsVisibility} onMouseEnter={openActions} onMouseLeave={closeActions} maxSmall={maxSmall}>
            {isOpen && (
              <>
                <Button mb={4} as="a" href={downloadUrl}>Download</Button>
                {smugmugUrl && <Button as="a" href={buyUrl} target="_blank">Order Prints</Button>}
                <IconButton
                  title="Close"
                  icon={<CloseIcon />}
                  color="memoOrange"
                  position="absolute"
                  top={3}
                  right={3}
                  onClick={closeActions}
                />
              </>
            )}
            <Copyright>
              Photo by <NoWrap>Chellise Michael Photography</NoWrap>
            </Copyright>
          </Actions>
          {!isOpen && isVisible && (
            <IconButton
              title="Actions"
              icon={<ActionsIcon />}
              color="memoOrange"
              position="absolute"
              top={3}
              right={3}
              onClick={openActions}
            />
          )}
        </>
      )}
    </Layout>
  );
}
