import css from '@styled-system/css';
import styled from 'styled-components';
import { flexbox, layout, position, space } from 'styled-system';

const Layout = styled.div(
  flexbox,
  layout,
  position,
  space,
  ({ height, showBackground }) => (
    css({
      backgroundImage: showBackground ? 'url("/images/flora.svg")' : 'none',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'top center',
      minHeight: height || 'viewportHeight'
    })
  )
);

export default Layout;
