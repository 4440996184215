import get from 'lodash.get';
import fetchData from '../util/fetchData';

import React from 'react';
import { useDocumentTitle, useQuery } from '../hooks';
import { useMediaQuery } from 'react-responsive'

import { Box, Image } from '../components';

import { MAX_SMALL } from '../consts/breakpoints';

export default function SearchResults({ query, width }) {
  useDocumentTitle(query);
  const maxSmall = useMediaQuery({ query: MAX_SMALL });

  const encodedQuery = encodeURIComponent(query);
  const { data: resource, isLoading, error } = useQuery(`search-${query}`, () => fetchData(`/images?q=${encodedQuery}`));

  if (error || isLoading || !resource) return null;
  if (error) console.error(error);

  const images = get(resource, 'data', []);

  return (
    <Box width={width}>
      {images.map(image => (
        <Image
          key={image.id}
          id={image.id}
          title={image.title}
          width={image.width}
          height={image.height}
          sizes={image.sizes}
          downloadUrl={image.downloadUrl}
          smugmugUrl={image.smugmugUrl}
          maxSmall={maxSmall}
        />
      ))}
    </Box>
  );
}
