import React from 'react';
import DocumentTitle from 'react-document-title';
import styled from 'styled-components';
import css from '@styled-system/css';

const Layout = styled('main')(
  css({
    display: 'flex',
    alignItems: 'center',
    backgroundImage: 'url("/images/flora.svg")',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top center',
    minHeight: '100vh',
    py: 6,
    textAlign: 'center'
  })
);

export default function Main({ children, title = 'CASTHART '}) {
  return (
    <DocumentTitle title={title}>
      <Layout>
        {children}
      </Layout>
    </DocumentTitle>
  );
}
