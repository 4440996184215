import css from '@styled-system/css';
import styled from 'styled-components';

import React, { useEffect, useRef, useState } from 'react';
import useClickOutside from '../hooks/useClickOutside';

import { IconButton } from '../components';
import { ReactComponent as MenuIcon } from '../icons/menu.svg';

import theme from '../theme';
import { EASE_IN_OUT_CIRC } from '../consts/easings';

const Backdrop = styled.div(
  ({ isOpen }) => (
    css({
      position: 'fixed',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      zIndex: 1,
      pointerEvents: isOpen
        ? 'auto'
        : 'none'
    })
  )
);

const StyledDrawer = styled.div(
  ({ isOpen }) => (
    css({
      width: 'sidebar',
      padding: 3,
      position: 'fixed',
      top: 0,
      left: isOpen
        ? 0
        : `calc(${theme.sizes.sidebar} * -1)`,
      bottom: 0,
      zIndex: 1,
      backgroundColor: 'transparent.darkestGreen',
      transition: `all 0.5s ${EASE_IN_OUT_CIRC}`
    })
  )
);

export default function Drawer({ children, path, onToggle }) {
  const drawerRef = useRef();
  const [isOpen, toggleDrawer] = useState(false);

  useClickOutside(drawerRef, (event) => {
    if (isOpen) toggleDrawer(false);
  });

  useEffect(() => {
    toggleDrawer(false);
  }, [path]);

  useEffect(() => {
    document.scrollingElement.style.overflow = isOpen
      ? 'hidden'
      : 'auto';

    if (typeof onToggle === 'function') onToggle(isOpen);
  }, [isOpen, onToggle]);

  return (
    <>
      <IconButton
        icon={<MenuIcon width={24} height={24} />}
        title="Open Menu"
        position="fixed"
        backgroundColor="memoOrange"
        color="blue"
        top={2}
        left={2}
        zIndex={1}
        isHidden={isOpen}
        onClick={() => toggleDrawer(true)}
      />
      <Backdrop isOpen={isOpen}>
        <StyledDrawer isOpen={isOpen} ref={drawerRef}>
          {children}
        </StyledDrawer>
      </Backdrop>
    </>
  );
}
