import styled from 'styled-components';
import { color, flexbox, layout, position, space } from 'styled-system';

const Background = styled.div(
  color,
  flexbox,
  layout,
  position,
  space
);

export default Background;
