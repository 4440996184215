import theme from '../theme';

const SMALL = theme.breakpoints[0];
const MEDIUM = theme.breakpoints[1];
const LARGE = theme.breakpoints[2];
const XL = theme.breakpoints[3];

export const MIN_SMALL = `(min-width: ${SMALL})`;
export const MIN_MEDIUM = `(min-width: ${MEDIUM})`;
export const MIN_LARGE = `(min-width: ${LARGE})`;
export const MIN_XL = `(min-width: ${XL})`;

export const MAX_SMALL = `(max-width: ${SMALL})`;
export const MAX_MEDIUM = `(max-width: ${MEDIUM})`;
export const MAX_LARGE = `(max-width: ${LARGE})`;
export const MAX_XL = `(max-width: ${XL})`;
