import styled from 'styled-components';
import css from '@styled-system/css';

import React, { useState } from 'react';

import { Link } from '@reach/router';
import { Background, BackgroundVideo, IconButton, Layout } from '../components';
import { IntroVideos } from '../text';
import { Measure } from '../Typography';

import LogoArtworkUrl from '../artwork/casthart.svg';
import { ReactComponent as CloseIcon } from '../artwork/close.svg';
import { ReactComponent as HelpIcon } from '../artwork/help.svg';

import theme from '../theme';
const { darkestBlue } = theme.colors;
const { viewportHeight } = theme.sizes;
const BG_OPACITY = 0.9;

const ToggleButton = ({ isOpen, toggleContent }) => (
  <IconButton
    icon={isOpen ? <CloseIcon /> : <HelpIcon />}
    color="pink"
    position="fixed"
    right={3}
    bottom={3}
    zIndex={1}
    onClick={toggleContent}
  />
);

const About = ({ toggleContent }) => (
  <>
    <Layout position="relative" zIndex={1} height="70vh" overflow="auto">
      <Measure>
        <IntroVideos />
      </Measure>
    </Layout>
    <ToggleButton isOpen={true} toggleContent={toggleContent} />
    <Background bg="blue" opacity={BG_OPACITY} position="absolute" top={0} right={0} bottom={0} left={0} />
  </>
);

const CasthartLogo = styled.img(
  css({
    width: '100%',
    maxWidth: '397px',
    opacity: BG_OPACITY,
  })
);

const NavLink = styled(Link)(
  css({
    cursor: 'pointer',
    userSelect: 'none',
    color: 'green',
    fontSize: 6,
    lineHeight: '10vh',
    fontFamily: 'ui',
    textDecoration: 'none',
    textShadow: `0 0 0 ${darkestBlue}, 1px 1px 0 ${darkestBlue}, 2px 2px 0 ${darkestBlue}, 3px 3px 0 ${darkestBlue}, 4px 4px 0 ${darkestBlue}`,
    textTransform: 'uppercase',
    '&:hover': {
      color: 'memoOrange'
    }
  })
);

const Casthart = ({ toggleContent, isOpen }) => (
  <>
    <Background bg="blue" opacity={BG_OPACITY} flexGrow={1} />
    <Layout width="90%" maxWidth={397} display="flex" flexDirection="column" alignContent="center" justifyContent="center" minHeight={viewportHeight}>
      <Background bg="blue" opacity={BG_OPACITY} display="flex" flexDirection="column" flexGrow={1} height={0.5} alignItems="center" justifyContent="center">
        <NavLink to="/video">Watch</NavLink>
        <NavLink to="/video-booth">Video Booth</NavLink>
      </Background>
      <CasthartLogo src={LogoArtworkUrl} />
      <Background bg="blue" opacity={BG_OPACITY} display="flex" flexDirection="column" flexGrow={1} height={0.5} alignItems="center" justifyContent="center">
        <NavLink to="/photos">Photos</NavLink>
        <NavLink to="/photos/albums/gLSXKF">Lit Lounge</NavLink>
      </Background>
    </Layout>
    <ToggleButton isOpen={isOpen} toggleContent={toggleContent} />
    <Background bg="blue" opacity={BG_OPACITY} flexGrow={1} />
  </>
);

export default function Splash() {
  const [content, setContent] = useState('splash');

  const toggleContent = () => (
    content === 'splash'
      ? setContent('about')
      : setContent('splash')
  );

  const layoutProps = {
    display: 'flex',
    height: viewportHeight,
    position: 'relative',
    zIndex: 1,
    flexBasis: 'row',
    alignItems: content === 'splash'
      ? 'stretch'
      : 'center',
    justifyContent: 'center'
  };

  return (
    <>
      <Layout {...layoutProps}>
        {content === 'splash'
          ? <Casthart toggleContent={toggleContent} />
          : <About toggleContent={toggleContent} />
        }
      </Layout>
      <BackgroundVideo />
    </>
  );
}
