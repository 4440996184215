import { useEffect } from 'react';

const SITE = 'CASTHART';
const SEP = ' ◆ ';

export default function useDocumentTitle(title, ...args) {
  useEffect(() => {
    document.title = title ? [title, ...args, SITE].join(SEP) : SITE;
  }, [title, args]);
}
