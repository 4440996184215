import styled from 'styled-components';
import { color, space } from 'styled-system';
import css from '@styled-system/css';

import React from 'react';
import PropTypes from 'prop-types';

const StyledButton = styled.button(
  color,
  space,
  css({
    cursor: 'pointer',
    userSelect: 'none',
    borderTopLeftRadius: 2,
    borderTopRightRadius: 2,
    borderBottomLeftRadius: 2,
    borderBottomRightRadius: 2,
    px: 3,
    py: 2,
    backgroundColor: 'darkGreen',
    color: 'green',
    fontFamily: 'ui',
    fontSize: 3,
    lineHeight: 2,
    letterSpacing: '0.1em',
    textDecoration: 'none',
    textTransform: 'uppercase',
    ':hover, :focus': {
      outline: 'none',
      backgroundColor: 'blue',
      color: 'memoOrange'
    }
  })
);

export default function Button({ children, ...props }) {
  return (
    <StyledButton {...props}>
      {children}
    </StyledButton>
  );
}

Button.propTypes = {
  children: PropTypes.string.isRequired
};
