const MIN_TARGET = '2.75rem'; // 44px

const breakpoints = [
  '500px',
  '896px',
  '1200px',
  '1440px'
];

const colors = {
  memoOrange:   'hsl(19,  87%,  84%)',
  lightPink:    'hsl(319, 54%,  93%)',
  pink:         'hsl(340, 52%,  69%)',
  burgundy:     'hsl(10,  67%,  29%)',
  green:        'hsl(129, 46%,  71%)',
  darkGreen:    'hsl(180, 43%,  21%)',
  darkestGreen: 'hsl(160, 30%,  16%)',
  lightYellow:  'hsl(45,  93%,  74%)',
  yellow:       'hsl(45,  93%,  84%)',
  lightBlue:    'hsl(230, 59%,  39%)',
  darkestBlue:  'hsl(230, 85%,  29%)',
  blue:         'hsl(230, 59%,  39%)',
  white:        'hsl(0,   0%,   100%)',
  transparent: {
    darkestBlue: 'hsla(230, 59%,  15%, 0.5)',
    darkestGreen: 'hsla(160, 30%,  16%, 0.5)',
    darkGreen: 'hsla(100, 43%,  21%, 0.8)',
    pink: 'hsla(340, 52%, 69%, 0.4)'
  }
};

const fonts = {
  display: 'Cocotte Webfont',
  text: 'Secession Webfont',
  ui: 'Cocotte Webfont'
};

const fontSizes = [
  '0.75rem',  // 12px
  '0.875rem', // 14px
  '1rem',     // 16px
  '1.125rem', // 18px
  '1.25rem',  // 20px
  '1.5rem',   // 24px
  '1.875rem'  // 30px
];

const lineHeights = [
  0,
  1,
  1.5,
  2
];

lineHeights.minTarget = MIN_TARGET;

const letterSpacings = [
  '0.1em',
  '0.4em'
];

const sizes = {
  icon: '2rem', // 32px
  minTarget: MIN_TARGET,
  measure: '50em',
  maxLayout: '80em',
  sidebar: '16rem',
  viewportHeight: 'calc(var(--vh, 1vh) * 100)'
};

const space = [
  0, 4, 8, 16, 32, 64, 128, 256, 512
];

space.smallMargin = space[3];
space.margin = space[4];

space.minTarget = MIN_TARGET;

const easing = {
  quick: 'cubic-bezier(0.785, 0.135, 0.15, 0.86)'
};

const radii = [
  '0',
  '0.125rem',
  '0.25rem',
  '0.5rem'
];

export default {
  breakpoints,
  colors,
  easing,
  fonts,
  fontSizes,
  letterSpacings,
  lineHeights,
  radii,
  sizes,
  space
};
