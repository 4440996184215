import React from 'react';
import { H3, Section, Text } from '../Typography';

const isSpanish = navigator.language.startsWith('es');

const StreetView = () => (
  <iframe
    title="Google Streetview of venue entrance"
    src="https://www.google.com/maps/embed?pb=!4v1568207286154!6m8!1m7!1sTIFJJgjKSJ3I1pHn6Rk2Yw!2m2!1d40.7498843029033!2d-73.94921096874286!3f143.08101424820055!4f19.429249484845514!5f0.7820865974627469"
    width="100%"
    height="400"
    frameBorder="0"
    allowFullScreen
  />
);

const En = () => (
  <>
    <H3>How do I get to the venue?</H3>
    <Text>We recommend taking an UBER, Lyft, or Juno. But if you want to save money, the (E, M, F, and 7) train is a 5-10 mins walk from Court Square.</Text>
  </>
);

const Es = () => (
  <>
    <H3>¿Cómo llego a la boda?</H3>
    <Text>Recomendamos que tomen un UBER, Lyft, Juno, o Car Service. Pero si quieres ahorrarte el dinero, el tren (E, M, F, y 7) está a 5-10 mins caminando de Court Square.</Text>
  </>
);

export default function CashGifts() {
  return (
    <Section>
      {isSpanish
        ? <Es />
        : <En />
      }
      <StreetView />
    </Section>
  );
}
