import React from 'react';
import { H3, NoWrap, Section, Text } from '../Typography';

const isSpanish = navigator.language.startsWith('es');

const En = () => (
  <>
    <H3>What the heck does <NoWrap>‘Fancy Florals’</NoWrap><br />mean?</H3>
    <Text>If you identify as female, it can be anything from a floral dress, blouse, skirt, jumpsuit, shoes, handbag, tights, jewelry, or even flowers in your hair.</Text>
    <Text>If you identify as male, you can get away with a floral suit, shirt, pocket square, trousers, tie, socks, shoes, or even jewelry — if you’re up to it.</Text>
    <Text>We basically want all our guests to be the flowers in our ‘Wicked Garden’.</Text>
  </>
);

const Es = () => (
  <>
    <H3>¿Qué carajo significa algo <NoWrap>‘Fino con Flores’?</NoWrap></H3>
    <Text>Si te identificas como mujer, puede ser un vestido con flores, blusa, falda, zapatos, cartera, pantimedias, joyería, o hasta flores en tu pelo.</Text>
    <Text>Si te identificas como hombre, basta con un chaleco con flores, camisa, pañuelo, pantalones de vestir, corbata, medias, zapatos, o joyería — si te animas.</Text>
    <Text>Básicamente queremos que todos nuestros invitados sean las flores en nuestro ‘Jardín Malvado’.</Text>
  </>
);

export default function FancyFlorals() {
  return (
    <Section>
      {isSpanish
        ? <Es />
        : <En />
      }
    </Section>
  );
}
