import get from 'lodash.get';
import fetchData from '../util/fetchData';

import React from 'react';
import { Image } from '../components';
import { useQuery } from '../hooks';

export default function Photo({ imageId }) {
  const { data: resource, isLoading, error } = useQuery(imageId, () => fetchData(`/images/${imageId}`));

  if (error || isLoading || !resource) return null;
  if (error) console.error(error);

  const image = get(resource, 'data', {});

  return (
    <Image
      width={image.width}
      height={image.height}
      title={image.title}
      sizes={image.sizes}
    />
  );
}
