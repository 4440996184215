import React from 'react';
import styled from 'styled-components';
import { color, space } from 'styled-system';
import css from '@styled-system/css';

const resetList = {
  margin: 0,
  padding: 0,
  listStyleType: 'none'
};

const textStyles = {
  color: 'white',
  fontFamily: 'text',
  fontWeight: 400,
  fontSize: 3,
  letterSpacing: 0,
  lineHeight: 3,
  textAlign: 'center',
  textShadow: '2px 2px hsla(0, 0%, 0%, 0.5)'
};

export const H1 = styled('h1')(
  css({
    display: 'inline-block',
    lineHeight: 0,
    marginBottom: 3
  }),
  space
);

export const H2 = styled('h2')(
  css({
    marginBottom: 3,
    color: 'green',
    fontWeight: 400,
    fontFamily: 'display',
    fontSize: 4,
    letterSpacing: 1,
    textAlign: 'center',
    textShadow: '2px 2px hsla(0, 0%, 0%, 0.5)',
    textTransform: 'uppercase'
  }),
  color,
  space
);

export const H3 = styled('h3')(
  css({
    marginBottom: 3,
    color: 'memoOrange',
    fontWeight: 400,
    fontFamily: 'display',
    fontSize: 1,
    letterSpacing: 1,
    textAlign: 'center',
    textShadow: '2px 2px hsla(0, 0%, 0%, 0.5)',
    textTransform: 'uppercase'
  }),
  color,
  space
);

export const H4 = styled('h4')(
  css({
    marginBottom: 3,
    fontWeight: 400,
    fontFamily: 'display',
    fontSize: 1,
    letterSpacing: 1,
    lineHeight: 2.2,
    textAlign: 'center',
    textShadow: '2px 2px hsla(0, 0%, 0%, 0.5)',
    textTransform: 'uppercase'
  }),
  color,
  space
);

export const Link = styled('a')(
  css({
    color: 'green',
    textDecoration: 'underline'
  })
);

export const List = styled('ul')(
  css(resetList)
);

export const ListItem = styled('li')(
  css(textStyles)
);

export const Text = styled('p')(
  css(textStyles),
  color,
  space
);

export const Measure = styled.div(
  {
    maxWidth: '34em'
  },
  space
);

export const NoWrap = styled('span')(
  {
    whiteSpace: 'nowrap'
  }
);

export const Section = styled('section')(
  css({
    marginBottom: 5,
  }),
  space
);

export const Strong = styled('strong')(
  css({
    color: 'green',
    fontWeight: 400
  })
);

const DotSeparator = ({ className }) => (
  <span className={className}>·</span>
);

export const Sep = styled(DotSeparator)(
  css({
    fontSize: 0,
    letterSpacing: 1,
    paddingLeft: 1,
    paddingRight: 1
  }),
  color
);
