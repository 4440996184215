import get from 'lodash.get';
import fetchData from '../util/fetchData';

import React from 'react';
import { useDocumentTitle, useQuery } from '../hooks';
import { useMediaQuery } from 'react-responsive'

import { Box, Image } from '../components';

import { MAX_SMALL } from '../consts/breakpoints';

export default function Album({ id, width }) {
  const { data: resource, error } = useQuery(id, () => fetchData(`/albums/${id}`));
  if (error) console.error(error);

  const maxSmall = useMediaQuery({ query: MAX_SMALL });

  const images = get(resource, 'data', []);
  const title = get(resource, 'meta.title', 'Photos');

  useDocumentTitle(title);

  return (
    <Box width={width}>
      {images.map(image => (
        <Image
          key={image.id}
          id={image.id}
          title={image.title}
          width={image.width}
          height={image.height}
          sizes={image.sizes}
          downloadUrl={image.downloadUrl}
          smugmugUrl={image.smugmugUrl}
          maxSmall={maxSmall}
        />
      ))}
    </Box>
  );
}
