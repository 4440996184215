import { parse } from 'querystring';
import css from '@styled-system/css';
import styled from 'styled-components';

import React, { useEffect, useState } from 'react';
import { useDocumentTitle } from '../hooks';
import { useMediaQuery } from 'react-responsive'

import { Flex, Layout, Nav } from '../components';
import { Album, CoverPhotos, SearchResults } from '../content';

import { MIN_LARGE } from '../consts/breakpoints';
import { EASE_OUT_QUART } from '../consts/easings';

function getPhotosComponent({ albumId, query }) {
  if (albumId) return <Album id={albumId} />;
  if (query) return <SearchResults query={query} />;
  return <CoverPhotos />;
}

const Main = styled.main(
  ({ isDrawerOpen }) => (
    css({
      filter: isDrawerOpen
        ? 'blur(2px)'
        : 'none',
      transition: `all 0.5s ${EASE_OUT_QUART}`
    })
  )
);

export default function Photos({ albumId, navigate, location }) {
  useDocumentTitle('Photos');
  const [isDrawerOpen, setDrawerState] = useState(false);
  const minLarge = useMediaQuery({ query: MIN_LARGE });

  let queryParams = window.location.search.split('?')[1];
  let parsedParams = {};
  if (queryParams) parsedParams = parse(queryParams);

  const query = parsedParams.q;

  function handleDrawerToggle(isOpen) {
    setDrawerState(isOpen);
  }

  useEffect(() => {
    setDrawerState(false);
  }, [minLarge]);

  return (
    <Layout width="100%" maxWidth="maxLayout" mx="auto" padding={['smallMargin', 'margin']}>
      <Flex display="flex">
        <Nav
          minLarge={minLarge}
          width="sidebar"
          navigate={navigate}
          path={location.pathname}
          onDrawerToggle={handleDrawerToggle}
        />
        <Main isDrawerOpen={isDrawerOpen}>
          {getPhotosComponent({ albumId, query, isDrawerOpen })}
        </Main>
      </Flex>
    </Layout>
  );
}
