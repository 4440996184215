import React from 'react';
import styled from 'styled-components'
import css from '@styled-system/css';

const Video = ({ className }) => (
  <iframe
    title="Montage of wedding movies"
    src="https://player.vimeo.com/video/358937686?background=1"
    className={className}
    frameBorder="0"
    allow="autoplay; fullscreen"
    allowFullScreen
  />
);

// https://stackoverflow.com/a/37309881
const CroppedVideo = styled(Video)(
  css({
    width: '100vw',
    height: '56.25vw',
    minHeight: '100vh',
    minWidth: '177.77vh',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  })
);

const VideoWrapper = ({ className }) => (
  <div className={className}>
    <CroppedVideo />
  </div>
);

const BackgroundVideo = styled(VideoWrapper)(
  css({
    width: '100%',
    height: '100%',
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 0
  })
);

export default BackgroundVideo;
