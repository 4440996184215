import React from 'react';
import { H2, Section, Text } from '../Typography';

const isSpanish = navigator.language.startsWith('es');

const En = () => (
  <>
    <H2>FAQ</H2>
    <Text>If you got the invitation, but still have questions, then you’re in the right place.</Text>
  </>
);

const Es = () => (
  <>
    <H2>Preguntas Más Frecuentes</H2>
    <Text>Si te llego la invitación, pero todavía tienes preguntas, pues estas en el lugar correcto.</Text>
  </>
);

export default function Faq() {
  return (
    <Section>
      {isSpanish
        ? <Es />
        : <En />
      }
    </Section>
  );
}
