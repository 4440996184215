import css from '@styled-system/css';
import get from 'lodash.get';
import fetchData from '../util/fetchData';
import styled from 'styled-components';

import React from 'react';
import { useQuery } from '../hooks';

import { Box, ContentBox, Drawer, Search } from '.';
import { Link, Match } from '@reach/router';

const styles = {
  display: 'inline-block',
  fontSize: 2,
  fontFamily: 'ui',
  fontWeight: 400,
  lineHeight: 'minTarget',
  textTransform: 'uppercase'
};

const CurrentAlbum = styled('strong')(
  css({
    ...styles,
    color: 'memoOrange',
  })
);

const NavLink = styled(Link)(
  css({
    ...styles,
    cursor: 'pointer',
    color: 'green',
    textDecoration: 'none',
    textTransform: 'uppercase',
    '&:hover': {
      color: 'pink'
    }
  })
);

const Rule = styled.hr(
  css({
    width: '100%',
    height: '2px',
    border: 0,
    borderStyle: 'solid',
    borderBottomWidth: '2px',
    borderColor: 'blue'
  })
);

export default function Nav({ navigate, path, width, minLarge, onDrawerToggle }) {
  const { data: albumsResource, albumsError } = useQuery('albums', () => fetchData('/albums'));
  const { data: guestsResource, guestsError } = useQuery('guests', () => fetchData('/guests'));

  const error = albumsError || guestsError;
  if (error || !albumsResource || !guestsResource) return null;
  if (error) console.error(error);

  const albums = get(albumsResource, 'data', []);
  const guests = get(guestsResource, 'data', []);

  function handleSearch(query = '') {
    const encodedQuery = encodeURIComponent(query);
    navigate(`/photos/search?q=${encodedQuery}`);
  }

  const content = (
    <>
      <Search options={guests} path={path} onChange={handleSearch} />
      {albums.map(album => (
        <Match key={album.id} path={`/photos/albums/${album.id}`}>
          {props => (
            <>
              {props.match
                ? <CurrentAlbum>{album.title}</CurrentAlbum>
                : <NavLink to={`/photos/albums/${album.id}`}>{album.title}</NavLink>}
              <br />
            </>
          )}
        </Match>
      ))}
      <Rule />
      <NavLink to="/video">Wedding Video</NavLink>
      <br />
      <NavLink to="/video-booth">Video Booth</NavLink>
    </>
  );

  if (!minLarge) {
    return (
      <Drawer path={path} onToggle={onDrawerToggle}>
        {content}
      </Drawer>
    );
  }

  return (
    <ContentBox width={width} flexShrink="0" pr="margin">
      <Box width={width} position="fixed" top="margin" left="margin">
        {content}
      </Box>
    </ContentBox>
  );
}
